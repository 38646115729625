const FastFooter = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.45378 16.8678L8.6701 16.6466L8.66757 16.3373L8.64073 13.0529L10.8839 11.1432L12.6403 16.8857L13.0105 18.0963L13.8944 17.19L14.4767 16.5929L14.7146 16.3489L14.6874 16.0093L14.0707 8.31825L14.3351 8.05307L15.0949 7.29116L16.308 6.07462L16.3081 6.07465L16.314 6.06858C16.9923 5.37281 17.2672 4.72192 17.3663 4.24441C17.4257 3.9579 17.4279 3.71047 17.3954 3.50504C17.3793 3.40337 17.3543 3.30971 17.322 3.22589C17.3002 3.16935 17.2467 3.03996 17.1379 2.92438L17.1288 2.91467L17.1193 2.9053C16.9957 2.78308 16.855 2.72073 16.7771 2.69056C16.681 2.65339 16.5781 2.62696 16.4741 2.60965C16.2644 2.57478 16.0153 2.57108 15.742 2.61698C15.1855 2.71042 14.5404 3.00687 13.9119 3.64607C13.9119 3.64611 13.9118 3.64614 13.9118 3.64618C13.9118 3.64621 13.9117 3.64624 13.9117 3.64627L13.9104 3.64759L11.6964 5.87371L3.9979 5.09502L3.63787 5.05861L3.38531 5.31775L2.7959 5.92254L1.94887 6.79165L3.10505 7.16052L8.89254 9.00694L6.97963 11.3648L3.64802 11.2009L3.30922 11.1842L3.07298 11.4276L2.95805 11.546L2.20081 12.3262L3.19908 12.757L6.06832 13.9952L7.11622 16.7166L7.53583 17.8064L8.35232 16.9716L8.45378 16.8678ZM8.4976 13.1747L8.49805 13.1743C8.49787 13.1745 8.49769 13.1746 8.49751 13.1748L8.4976 13.1747ZM11.3533 10.7436C11.3532 10.7437 11.3532 10.7437 11.3531 10.7438L11.3533 10.7436ZM13.9398 8.44958L13.9401 8.44926C13.94 8.44938 13.9398 8.44949 13.9397 8.44961L13.9398 8.44958Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default FastFooter
